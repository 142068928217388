.container {
  margin: 0 !important;
}

.card {
  flex: 1;
}

.cardContent {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.button {
  flex: 1;
}

.timeline {
  flex: 1;
}
